import React from "react";
import Oneijah from "../AllTabs/oneijah";
import ExtinctionLove from "../AllTabs/extinctionLove";
import FiregateFour from "../AllTabs/firegateFour";
import Psychometrics from "../AllTabs/psychometrics";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
	desktop: {
	  breakpoint: { max: 3000, min: 1024 },
	  items: 1,
	  slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
	  breakpoint: { max: 1024, min: 464 },
	  items: 1,
	  slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
	  breakpoint: { max: 464, min: 0 },
	  items: 1,
	  slidesToSlide: 1 // optional, default to 1.
	}
  };
  
const WorkTabs = () => {
	return (
		<div className="work-tabs">
			<Carousel 
				responsive={responsive}
				swipeable={true}
				draggable={false}
				showDots={true}
				renderDotsOutside={false}
				focusOnSelect={true}
				ssr={true}
				infinite={true}
				keyBoardControl={true}
  				customTransition="all .5"
				transitionDuration={500}
  				containerClass="carousel-container"
				dotListClass="custom-dot-list-style"
				itemClass="carousel-item-padding-40-px"
			>
				<div><ExtinctionLove /></div>
				<div><FiregateFour /></div>
				<div><Oneijah /></div>
				<div><Psychometrics /></div>
			</Carousel>
		</div>
	);
};
export default WorkTabs;