import React from "react";
import WorkTabs from "../TabComponent/work-tabs";

const Work = () => {
  return (
    <div className="work-tabs-container">
      <WorkTabs />
    </div>
  );
};
export default Work;