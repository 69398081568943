import React, { useState } from "react";

const FiregateFour = () => {

  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div className="firegate-four bookPanel">
      <div className="textContainer">
        <h4>Firegate Four</h4>
        <h3>STATUS</h3>
        <p>In Progress. Draft #4</p>
        <h3>ELEVATOR PITCH</h3>
        <p>As people go missing and gruesome murders mount in Philadelphia, the fates of three teenagers, unknown to each other, converge on their discovery of another world where they not only uncover the mystery of those crimes but discover who they are.</p>
        <h3>INFLUENCES / COMPARISONS</h3>
        <div class="comps">
          <p><i>Fullmetal Alchemist</i>, Hiromu Arakawa</p> 
          <p><i>The Water Dancer</i>, Ta-Nehisi Coates</p> 
          <p><i>His Dark Materials</i>, Phillip Pullman</p>
          <p className="full-width"><i>The Boy and the Heron</i>(film), dir. Hayao Miyazaki</p>
        </div>
        <h3>AUTHOR'S NOTE</h3>
        <p>A hot mess but a wild ride. Fatalistic but earnestly cheerful. It all comes together though. When society breaks along cultural/political/racial lines to whom does the American experience belong? To whom its culture? Its legacies? First book of three.</p>
        <div className={`read-more-text-ff ${isExpanded ? "show" : ""}`}>
          <hr />
          <p>The president of the United States is a white supremacist. Anti-Muslim, anti-immigrant and anti-black legislation is incrementally enacted. The American fabric is changing, people are changing. In Philadelphia, people are reported missing. But it's when gruesome murders mount that the City of Brotherly Love becomes a police state. Paranoia fills hearts and minds. Militias rule the streets.</p>
          <p>Almas Ananda Virk, 14, witnesses one such murder and glimpses the otherworldly elements responsible for it. Already she’s a paragon of DSM-IV symptomatology and, being Sikh-American, a presumed Muslim wherever she goes. But now her imaginary brother of yore has returned. She sees ghostly figures others don’t see. They follow her to her home. Then her mother goes missing.</p>
          <p>Solomon Harding, 15, is a runaway. Federal and state budget cuts have caused the closure of his foster home. His best friend, Natalie, left him with a codex before running away herself. The codex provides an account of an unlikely courtship between a free black woman and Tep Monnik, a man from an alternate world, during Philadelphia's Yellow Fever Epidemic of 1793. As Solomon draws clues from the book for how he can reach that alternate world, he realizes that Tep Monnik’s story not only unveils Solomon’s past but also hints why he may be the next tally in Philadelphia's murder count.</p>
          <p>When Esti Ybarra, 16, discovers the dead body of one of Philadelphia's missing persons in her basement, she believes her father is responsible. Esti immigrated with her father to the US from Spain two years ago. He's voiced his support for President Hough but is otherwise a secretive man. As Esti investigates what these secrets might be, she’s shocked to learn how much they pertain to her, too.</p>
          <p>As Almas searches for her mother, Solomon seeks an understanding of his past and Esti looks for answers to her mysterious father, their paths intertwine and lead them to an alternate world that will provide answers and lure them into deeper mysteries.</p>
          <hr />
        </div>
        <button onClick={handleClick}>
          {isExpanded ? "--READ LESS--" : "--READ MORE--"}
        </button>
      </div>
      <div className="imageContainer">
        <img src="../img/FiregateFour.png" alt="Firegate Four Cover Art" />
      </div>
    </div>
  );
};
export default FiregateFour;