import React, { useState } from "react";
import Work from "../AllTabs/work";
import Bio from "../AllTabs/bio";

function TopTabs() {
	const [activeTab, setActiveTab] = useState("tab1");
	const handleTab1 = () => { setActiveTab("tab1"); };
	const handleTab2 = () => { setActiveTab("tab2"); };
	return (
		<div className="top-tabs">
			<ul className="nav">
				<li className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>
					WORK
				</li>
				<li className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>
					BIO
				</li>
			</ul>
			<div className="outlet">
				{activeTab === "tab1" ? <Work /> : <Bio />}
			</div>
		</div>
	);
}

export default TopTabs;