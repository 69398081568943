import React, { useState } from "react";

function Oneijah() {

  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div className="oneijah bookPanel">
      <div className="imageContainer">
        <img src="../img/oneijah.png" alt="Oneijah bookcover" />
      </div>
      <div className="textContainer">
        <h4>Oneijah</h4>
        <h3>STATUS</h3>
        <p>Complete. To be self-published. Late 2025 / Early 2026 Release.</p>
        <h3>ELEVATOR PITCH</h3>
        <p>Oneijah is the story of an unscrupulous mother of seven who, amid a genocide, must fight to keep her dreams and children alive.</p>
        <h3>INFLUENCES / COMPARISONS</h3>
        <div class="comps">
          <p>Dungeons & Dragons</p> 
          <p><i>Mother India</i>(film), dir. Mehboob Khan</p> 
          <p><i>The Road</i>, Cormac McCarthy</p>
          <p><i>Vagabond</i>, Takehiko Inoue</p>
          <p><i>The Fifth Child</i>, Dorris Lessing</p>
        </div>
        <h3>AUTHOR'S NOTE</h3>
        <p>First book in a long series. High and low elements of fantasy storytelling. A rough, tough read in more ways than one, though laced with unexpected humor. Oneijah and her children will burrow into you head, if not quite your heart.</p>
        <div className={`read-more-text-o ${isExpanded ? "show" : ""}`}>
          <hr />
          <p>Oneijah is a laborer in a hot, inhospitable terrain known as the Onkara Lands. That she’s a nascent mage must be concealed because she is unliked and unsanctioned. She burns to make more of herself. But she is terribly misanthropic, dogged by past failures, dark-complected, crippled with anxiety and weighted by the needs of seven children. One night the Murkhand, occult creatures from beyond the Onkara Lands, attack her village. When her magic fails her, her favorite son is killed. She and her five remaining children are the only survivors.</p>
          <p>She musters the will to gather provisions and leave their homeland. Dangers accrue. A Murkhand is following them. A drug-addled deserter - an elite soldier of a lighter-complected, enemy race  - has offered to guide them north. More difficult still, she must be mother to children whose grieving is no less malformed than her own. But she refuses to relent in her quest to become a more powerful mage, leading to choices that turnwise harden, alienate, diminish and enrage her children. Ambition and parenthood are continually at odds as she fights not only for their survival but a place for herself in a world set against her.</p>
          <hr />
        </div>
        <button onClick={handleClick}>
          {isExpanded ? "--READ LESS--" : "--READ MORE--"}
        </button>
      </div>
    </div>
  );
}

export default Oneijah;
