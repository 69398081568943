import React, { useState } from "react";

const Psychometrics = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div className="psychometrics bookPanel">
      <div className="imageContainer">
        <img src="../img/psychometrics.png" alt="Psychometrics for the Soul Cover Art" />
      </div>
      <div className="textContainer">
        <h4>Psychometrics for the Soul</h4>
        <h3>STATUS</h3>
        <p>Next up. In Progress.</p>
        <h3>ELEVATOR PITCH</h3>
        <p>An Indian-American couple’s failing marriage is set to a backdrop of a husband's rightward turn, a wife's professional ambition and the pandemic in its throes.</p>
        {/* <h3>INFLUENCES / COMPARISONS</h3>
        <div class="comps">
          <p><i>The Dead</i>, James Joyce</p> 
          <p><i>On Chesil Beach</i>, Ian McEwan</p> 
          <p><i>Life</i>, Keith Richards</p>
          <p><i>Dom Casmurro</i>, Machado de Assis</p>
        </div>
        <h3>AUTHOR'S NOTE</h3>
        <p>A hot mess but a wild ride. Still, it all comes together. In a fascist America, what is the value of ethnicity? What of the American experience are you permitted access? Which of America's legacies do you inherit, if any? Firegate Four doesn't give clean answers to these questions. But these preoccupations underlie much of the action.</p> */}
        {/* Conditionally render the extra text based on isExpanded */}
        {/* <div className={`read-more-text-psy ${isExpanded ? "show" : ""}`}>
          <hr />
          <p>Oneijah is a laborer in a hot, inhospitable terrain known as the Onkara Lands. That she’s a nascent mage must be concealed because she is unliked and unsanctioned. She burns to make more of herself. But she is terribly misanthropic, dogged by past failures, dark-complected, crippled with anxiety and weighted by the needs of seven children. One night the Murkhand, occult creatures from beyond the Onkara Lands, attack her village. When her magic fails her, her favorite son is killed. She and her five remaining children are the only survivors.</p>
          <p>She musters the will to gather provisions and leave their homeland. Dangers accrue. A Murkhand is following them. A drug-addled deserter - an elite soldier of a lighter-complected, enemy race - has offered to guide them north. More difficult still, she must be mother to children whose grieving is no less malformed than her own. But she refuses to relent in her quest to become a more powerful mage, leading to choices that turnwise harden, alienate, diminish, and enrage her children. Ambition and parenthood are continually at odds as she fights not only for their survival but a place for herself in a world set against her.</p>
          <hr />
        </div>
        
        <button onClick={handleClick}>
          {isExpanded ? "--READ LESS--" : "--READ MORE--"}
        </button> */}
      </div>
    </div>
  );
};

export default Psychometrics;